import React from 'react';
import './Footer.css'

const Footer = () => {
  return (
    <div className='app__footer flex__center'>
      <p>2024 - Rajin Sakha &copy; . All Rights Reserved.</p>
      
    </div>
  )
}

export default Footer
